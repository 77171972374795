import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider from '@unlikelystudio/react-slider'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface TextHorizontalScrollingProps {
  className?: string
  itemClassName?: string
  text?: string
  repeatText?: number
  theme?: string
  preset?: string
  speed?: number
}

const REPEAT_TEXT = 20

function TextHorizontalScrolling({
  className,
  itemClassName,
  text,
  repeatText,
  theme,
  preset,
  speed = 0.4,
}: TextHorizontalScrollingProps) {
  const textPreset = useStyle({
    textPreset: preset,
    textStyling: GlobalTextStyling.UpperCase,
    color: theme,
  })

  const texts = Array(repeatText ?? REPEAT_TEXT)
    .fill(null)
    ?.map((item, index) => (
      <span
        key={`${index}`}
        className={cx(css.text, textPreset, itemClassName)}
      >
        {text}
      </span>
    ))

  return (
    <div
      className={cx(css.TextHorizontalScrolling, className, {
        isWhite: theme === GlobalThemeColors.White,
      })}
    >
      <Slider
        className={css.slider}
        infinite={true}
        autoPlay={{ speed: -speed }}
        autoIsSlider={false}
      >
        {texts}
      </Slider>
    </div>
  )
}

TextHorizontalScrolling.defaultProps = {}

export default TextHorizontalScrolling
