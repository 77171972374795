import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextSize } from '~/@types/text-size'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import ProductCard, { ProductCardProps } from '~/components/Slices/ProductCard'
import SquaredCta, { SquaredCtaProps } from '~/components/UI/SquaredCta'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingProductImpression from '~/hooks/useTrackingProductImpresion'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = {
  GRID: [{ breakpoint: 'md', ratio: 6 / 24 }, { ratio: 12 / 24 }],
}

const TITLE_SIZES = {
  [GlobalTextSize.Big]: GlobalTextPreset.Title30_60HafferSemiBold,
  [GlobalTextSize.Small]: GlobalTextPreset.Title18_24HafferSemiBold,
}

export interface ProductsGridEditoProps {
  className?: string
  suptitle?: RichTextBlocks
  title?: RichTextBlocks
  products?: ProductCardProps[]
  cta?: SquaredCtaProps
  layout?: GlobalTextSize
}

function ProductsGridEdito({
  className,
  suptitle,
  title,
  products,
  cta,
  layout,
}: ProductsGridEditoProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const suptitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12_14Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const titleStyle = useStyle({
    textPreset: TITLE_SIZES[layout],
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const { ref } = useTrackingProductImpression(products)

  const { position: slicePosition } = useSliceProvider()

  return products?.length > 0 ? (
    <div ref={ref} className={cx(css.ProductsGridEdito, className, gridStyle)}>
      <RichText className={cx(css.suptitle, suptitleStyle)} render={suptitle} />
      <RichText className={cx(css.title, titleStyle)} render={title} />

      <div className={cx(css.content)}>
        {products?.map((product, index) => (
          <ProductCard
            key={`product_${index}`}
            className={cx(css.product)}
            cardWidthClassName={css.cardWidth}
            sizesFromBreakpoints={IMAGE_SIZES.GRID}
            priority={slicePosition === 1}
            {...product}
          />
        ))}
      </div>

      <div className={cx(css.footer)}>{cta && <SquaredCta {...cta} />}</div>
    </div>
  ) : null
}

ProductsGridEdito.defaultProps = {}

export default ProductsGridEdito
