import classnames from 'classnames/bind'
import React from 'react'

import FormEvent, { FormEventProps } from '~/components/UI/FormEvent'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface FormEventSliceProps extends FormEventProps {
  className?: string
}

function FormEventSlice({ className, ...rest }: FormEventSliceProps) {
  return (
    <div className={cx(css.FormEventSlice, className)}>
      <FormEvent {...rest} />
    </div>
  )
}

FormEventSlice.defaultProps = {}

export default FormEventSlice
