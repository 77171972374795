import useScrollPosition from '@react-hook/window-scroll'
import classnames from 'classnames/bind'
import { AnimatePresence, m, useSpring } from 'framer-motion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { GYGIA_TAGS } from '~/lib/shopify-tags'

import { CloseIcon } from '~/components/Abstracts/Icons'
import FormEvent, { FormEventProps } from '~/components/UI/FormEvent'
import Image, { ImageProps } from '~/components/UI/Image'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'
import TextHorizontalScrolling from '~/components/UI/TextHorizontalScrolling'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useLocale from '~/hooks/useLocale'
import {
  setCookiesAttempts,
  useIsAllowedToShowNewsletter,
} from '~/hooks/useNewsletterPopIn'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface HeroMainTeasingScrollProps {
  className?: string
  repeatSmallText?: string
  repeatBigText?: string
  image?: ImageProps
  imageMobile?: ImageProps
  video?: string
  links?: InlineCtaProps[]
  formTitle?: FormEventProps['title']
  formText?: FormEventProps['text']
  formCheckboxLabel?: FormEventProps['checkboxLabel']
  formLegals?: FormEventProps['legals']
  formTag?: FormEventProps['tag']
  campaign?: FormEventProps['campaign']
}

function HeroMainTeasingScroll({
  className,
  image,
  imageMobile,
  repeatSmallText,
  repeatBigText,
  links,
  formTitle,
  formText,
  formCheckboxLabel,
  formLegals,
  formTag,
  campaign,
}: HeroMainTeasingScrollProps) {
  // Getting the slice position to set the correct priority
  const { position: slicePosition } = useSliceProvider()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const isFocus = useRef(false)
  const bottomRef = useRef(null)

  const [enablePanel, setEnablePanel] = useState(false)
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })

  const scrollYOnOpen = useRef(null)
  const componentRef = useRef(null)

  const [canHavePanel, setCanHavePanel] = useState(true)

  const isAllowed = useIsAllowedToShowNewsletter()
  const scrollY = useScrollPosition(60)
  const isMobile = useBreakpoint(GlobalBreakpoints.MD)
  const locale = useLocale()

  const showPanel = canHavePanel && enablePanel
  const transformY = useSpring(0, {
    damping: 150,
    stiffness: 2000,
  })

  const openPanel = useCallback(() => {
    setEnablePanel(true)
  }, [setEnablePanel])

  useEffect(() => {
    if (inView && !showPanel && isAllowed) {
      openPanel()
    }
  }, [inView, openPanel, showPanel])

  const scrollYRef = useRef(null)
  useEffect(() => {
    scrollYRef.current = scrollY
  }, [scrollY])

  const [animationEnd, setAnimationEnd] = useState(false)

  useEffect(() => {
    if (
      animationEnd &&
      scrollY !== scrollYOnOpen.current &&
      isFocus.current === false
    ) {
      setCanHavePanel(false)
      setAnimationEnd(false)
      setCookiesAttempts(locale)
      scrollYOnOpen.current = null
    }
  }, [scrollY, animationEnd])

  const formProps: FormEventProps = useMemo(
    () => ({
      title: formTitle,
      text: formText,
      checkboxLabel: formCheckboxLabel,
      legals: formLegals,
      setCanHavePanel: setCanHavePanel,
      hasClose: true,
      onFocus: () => {
        isFocus.current = true
      },
      onBlur: () => {
        isFocus.current = false
      },
      onSuccess: () => {
        setCookiesAttempts(locale)
      },
      tag: GYGIA_TAGS.WEBSITE_POPUP,
      campaign,
    }),
    [
      formTitle,
      formText,
      formCheckboxLabel,
      formLegals,
      setCanHavePanel,
      isFocus,
      locale,
      campaign,
    ],
  )

  return (
    <section
      className={cx(css.HeroMainTeasingScroll, className)}
      ref={componentRef}>
      <div
        className={css.imageContainer}
        onClick={() => {
          openPanel()
          setCanHavePanel(true)
        }}>
        {image && (
          <div className={cx(css.imageContent, css.hideOnSmallScreen)}>
            <m.div
              className={css.imageContentAnimated}
              initial={{ scale: 1.05, opacity: 0 }}
              animate={{
                scale: 1.0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                  duration: 1.25,
                  ease: [0.345, 0.45, 0.39, 0.94],
                },
              }}>
              <Image
                className={cx(css.image)}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={slicePosition ? slicePosition === 1 : false}
                {...image}
              />
            </m.div>
          </div>
        )}

        {imageMobile && (
          <div className={cx(css.imageContent, css.hideOnLargeScreen)}>
            <m.div
              className={css.imageContentAnimated}
              initial={{ scale: 1.05, opacity: 0 }}
              animate={{
                scale: 1.0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                  duration: 1.25,
                  ease: [0.345, 0.45, 0.39, 0.94],
                },
              }}>
              <Image
                className={cx(css.image)}
                layout="fill"
                objectFit="cover"
                asPlaceholder
                priority={slicePosition ? slicePosition === 1 : false}
                {...imageMobile}
              />
            </m.div>
          </div>
        )}

        <div className={css.stickyContainer}>
          <div className={cx(css.links, gridStyle)}>
            <div className={css.linksContainer}>
              {links?.map((link, index) => (
                <InlineCta
                  onClick={(e) => e.stopPropagation()}
                  key={`inline_cta_${index}`}
                  textPreset={GlobalTextPreset.Cta14HafferSemiBold}
                  textStyling={GlobalTextStyling.UpperCase}
                  theme={GlobalThemeColors.Black}
                  lineProps={{ initialLineState: 'none' }}
                  {...link}
                />
              ))}
            </div>
          </div>

          <m.div
            className={css.repeatTextsContainer}
            style={{ y: isMobile ? 0 : transformY }}>
            <div className={css.repeatTexts}>
              {repeatSmallText && (
                <TextHorizontalScrolling
                  className={css.repeatText}
                  itemClassName={css.repeatSmallItem}
                  text={repeatSmallText}
                  theme={GlobalThemeColors.Black}
                  preset={GlobalTextPreset.Title14HafferSemiBold}
                  speed={0.3}
                />
              )}

              {repeatBigText && (
                <TextHorizontalScrolling
                  className={css.repeatText}
                  text={repeatBigText}
                  theme={GlobalThemeColors.Black}
                  preset={GlobalTextPreset.Title24HafferSemiBold}
                  speed={0.7}
                />
              )}
            </div>
          </m.div>
        </div>
        <div className={css.marker} ref={inViewRef} />
      </div>

      <AnimatePresence>
        {showPanel && (
          <m.div className={cx(css.bottomFormContainer)}>
            <m.div
              className={css.background}
              onClick={() => {
                setCookiesAttempts(locale)
                setCanHavePanel(false)
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, ease: 'linear' }}
            />
            <m.div
              className={cx(css.bottomForm)}
              ref={bottomRef}
              initial={{ opacity: 0, translateY: '20%' }}
              animate={{
                opacity: 1,
                translateY: '0%',
                transition: { duration: 0.3, ease: 'easeOut' },
              }}
              onAnimationComplete={(anim) => {
                if (animationEnd === false && anim['translateY'] === '0%') {
                  setTimeout(() => {
                    scrollYOnOpen.current = scrollYRef.current
                    setAnimationEnd(true)
                  }, 500)
                }
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.2,
                  ease: 'easeIn',
                },
              }}>
              <FormEvent {...formProps} />

              <CloseIcon
                theme={'black'}
                className={cx(css.closeIcon)}
                onClick={() => {
                  setCookiesAttempts(locale)
                  setCanHavePanel(false)
                }}
              />
            </m.div>
          </m.div>
        )}
      </AnimatePresence>
    </section>
  )
}

HeroMainTeasingScroll.defaultProps = {}

export default HeroMainTeasingScroll
